<template>
  <div>
    <st-modal
      id="st-modal-system-settings"
      hide-header-delimiter
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
      :visible="visible"
      size="md"
      @hide="hide"
      @modalClosed="hide"
      :title="$t('SYSTEM_SETTINGS.FORM.EDIT_TITLE')"
      customClass="form-modal"
    >
      <template #body>
        <form
          class="form"
          novalidate="novalidate"
          id="st_modal_system_settings_form"
        >
          <div class="row pb-6">
            <div class="col">
              <div class="form-group">
                <label> {{ fields.key.label }} </label>
                <st-input-text
                  ref="key"
                  name="key"
                  :value="$t(`SYSTEM_SETTINGS.KEYS.${model[fields.key.name]}`)"
                  disabled
                />
              </div>
              <div class="form-group">
                <label> {{ fields.default_value.label }} </label>
                <b-form-checkbox
                  v-if="model[fields.setting_type.name] === 'boolean'"
                  switch
                  :checked="model[fields.default_value.name]"
                  @change="
                    () =>
                      (model[fields.default_value.name] =
                        !model[fields.default_value.name])
                  "
                  size="lg"
                >
                  {{ model[fields.default_value.name] ? $t("GENERAL.ACTIVE") : $t("GENERAL.INACTIVE")}}
                </b-form-checkbox>
                <st-input-text
                  v-if="model[fields.setting_type.name] === 'string'"
                  ref="default_value"
                  name="default_value"
                  v-model="model[fields.default_value.name]"
                />
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <st-button size="large" variant="link" :callback="hide">
              {{ $t("GENERAL.BUTTON.CANCEL") }}
            </st-button>
            <st-button size="large" variant="primary" :callback="doSubmit">
              <span>{{ $t("GENERAL.BUTTON.SAVE") }}</span>
            </st-button>
          </div>
        </form>
      </template>
    </st-modal>
  </div>
</template>

<script>
import { FormSchema } from "@/shared/form/form-schema";
import { createFormValidation } from "@/shared/utils/create-form-validation";
import { SystemSettingsModel } from "@/modules/system-settings/system-settings-model";

const { fields } = SystemSettingsModel;

const formSchema = new FormSchema([
  fields.id,
  fields.key,
  fields.default_value,
  fields.setting_type,
]);

import { mapActions, mapGetters } from "vuex";

export default {
  name: "SystemSettingsFormModal",
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
      rules: formSchema.rules(),
      model: null,
    };
  },
  computed: {
    ...mapGetters({
      record: "systemSettings/form/record",
      filters: "systemSettings/list/filters",
    }),
    fields() {
      return fields;
    },
  },
  methods: {
    ...mapActions({
      doUpdate: "systemSettings/form/update",
      doFetch: "systemSettings/list/doFetch",
      fetchSettings: "shared/fetchSettings",
    }),
    hide() {
      this.$emit("close");
      this.doReset();
    },
    doReset() {
      this.model = formSchema.initialValues(this.record);
    },
    open() {
      this.visible = true;
    },
    async doSubmit() {
      const validate = await this.fv.validate();
      if (validate === "Valid") {
        const { id, ...values } = formSchema.cast(this.model);
        this.doUpdate({ id, values }).then(() => {
          this.fetchSettings();
          this.hide();
        });
      }
    },
  },
  async created() {
    this.model = formSchema.initialValues(this.record);
    console.log(this.model);
  },
  mounted() {
    // Initialize the form after the modal is shown
    this.$root.$on("bv::modal::shown", (modalObj) => {
      if (modalObj.componentId === "st-modal-system-settings") {
        this.fv = createFormValidation(
          "st_modal_system_settings_form",
          this.rules
        );
      }
    });
  },
};
</script>

